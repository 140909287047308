<template>
  <div>
    <a-row
      class="d-flex align-items-center mb-4"
      style="margin: -30px; padding: 22px 30px; background: #fff;"
    >
      <a-col :span="10">
        <h3 class="mb-0" style="font-size: 20px;">Splashscreens</h3>
      </a-col>
      <a-col :span="14" class="text-right">
        <a-tooltip class="mr-4" title="Refresh Splashscreens" placement="bottom">
          <a href="javascript:" class="text-primary font-size-24" @click="getSplashscreens">
            <a-icon type="sync" :spin="false" style="margin-top: 8px" />
          </a>
        </a-tooltip>
        <a-button size="large" class="mr-2" @click="filtersDrawer = true">
          <a-icon type="filter" /> Filters
        </a-button>
        <a-button
          type="primary"
          size="large"
          style="font-size: 16px; font-weight: 300; border-radius: 2px;"
          @click="createBannerModal"
        >
          <a-icon type="plus" /> Create new
        </a-button>
      </a-col>
    </a-row>
    <a-drawer
      title="Splashscreens Filters"
      placement="top"
      :closable="true"
      :visible="filtersDrawer"
      :wrapStyle="{ opacity: '1.0', }"
      :maskStyle="{ opacity: '0', backgroundColor: 'rgba(0,0,0,0.0)'}"
      height="176px"
      wrapClassName="filter-drawer"
      @close="filtersDrawer = false"
      class="filter-drawer"
    >
      <a-row class="mb-0 pb-0 mt-0 pt-0">
        <a-col :xs="{ span: 24 }" :sm="{ span: 12 }" :md="{ span: 12 }" :lg="{ span: 6 }" class="p-1">
          <a-form-model-item ref="filter-localizations" label="Localizations" prop="filter-localizations">
            <a-select
              v-model="filteredApps"
              mode="multiple"
              style="width: 100%"
              placeholder="Select apps"
              size="large"
              :maxTagCount="1"
              @change="handleAppsChange"
            >
              <a-select-option v-for="option in apps" :key="option.application_id" :value="option.application_id">
                <a-icon v-if="option.platform.toLowerCase() === 'android'" type="android" class="text-success" />
                <a-icon v-if="option.platform.toLowerCase() === 'ios'" type="apple" class="text-primary" />
                <a-icon v-if="option.platform.toLowerCase() === 'web'" type="global" class="text-default" />
                {{option.name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :xs="{ span: 24 }" :sm="{ span: 12 }" :md="{ span: 12 }" :lg="{ span: 4 }" class="p-1">
          <a-form-model-item ref="filter-active" label="Active status" prop="filter-active">
            <span class="banners-active-filter-switch ant-select-selection mr-2 font-size-16 pl-2">
              Active only
              <a-switch v-model="activeOnly" default-checked @change="handleActiveSwitch" />
            </span>
          </a-form-model-item>
        </a-col>
        <a-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 12 }" :lg="{ span: 7 }" class="p-1">
          <a-form-model-item ref="filter-localizations" label="Localizations" prop="filter-localizations">
            <a-select
              v-model="filteredLocalizations"
              mode="multiple"
              style="width: 100%"
              size="large"
              placeholder="Select languages"
              :maxTagCount="2"
              @change="handleLocalizationChange"
            >
              <a-select-option v-for="lang in localizations" :key="lang.value" :value="lang.value">
                {{lang.label}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-drawer>
    <a-row>
      <div v-if="!splashscreensLoading && !filteredSplashscreens.length" class="d-flex align-items-center justify-content-center bg-white" :style="{ height: 'calc(100vh - 64px - 89px - 40px)' }">
        <div class="text-center">
          <svg width="184" height="117" viewBox="0 0 184 117" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M92 117C142.81 117 184 108.057 184 97.0244C184 85.9922 142.81 77.0488 92 77.0488C41.1898 77.0488 0 85.9922 0 97.0244C0 108.057 41.1898 117 92 117Z" fill="#F5F5F5"/>
              <path d="M158.125 39.2662L128.955 6.44342C127.555 4.20615 125.511 2.85352 123.358 2.85352H60.6424C58.489 2.85352 56.4449 4.20615 55.0447 6.44056L25.875 39.2691V65.634H158.125V39.2662Z" stroke="#D9D9D9"/>
              <path d="M119.637 48.3152C119.637 43.7351 122.495 39.954 126.04 39.9512H158.125V91.708C158.125 97.7663 154.33 102.732 149.644 102.732H34.3562C29.67 102.732 25.875 97.7634 25.875 91.708V39.9512H57.96C61.5049 39.9512 64.3626 43.7266 64.3626 48.3067V48.3695C64.3626 52.9496 67.252 56.6479 70.794 56.6479H113.206C116.748 56.6479 119.637 52.9153 119.637 48.3352V48.3152V48.3152Z" fill="#FAFAFA" stroke="#D9D9D9"/>
          </svg>
          <div class="text-gray-7">No splashscreens</div>
        </div>
      </div>
      <div class="grid bg-white" :style="{ padding: '24px', minHeight: 'calc(100vh - 64px - 89px - 40px)' }">
        <div v-if="splashscreensLoading" class="splashscreens-loading-spinner text-center">
          <a-icon type="loading" class="text-primary" />
        </div>
        <splashscreen-card
          v-for="splashscreen in filteredSplashscreens"
          :key="splashscreen.id"
          :splashscreen-info="splashscreen"
          @click="editSplashscreenObj = splashscreen, editModal = true"
        />
      </div>
    </a-row>
    <a-modal
      v-model="addModal"
      title="New Splashscreen"
      :footer="null"
      class="splashscreen-modal"
      width="790px"
      :destroyOnClose="true"
    >
      <add-splashscreen-component
        :apps="apps"
        :langs="localizations"
        @closeModalFunction="handleAddSplashscreen"
        @submitModalFunction="handleCreateSplashscreen"
      />
    </a-modal>
    <a-modal
      v-model="editModal"
      title="Edit Splashscreen"
      @ok="handleEditSplashscreen"
      :footer="null"
      class="splashscreen-modal"
      width="790px"
      :destroyOnClose="true"
    >
      <edit-splashscreen-component
        :splashscreen="editSplashscreenObj"
        :apps="apps"
        :langs="localizations"
        @closeModalFunction="editModal = false"
        @deleteModalFunction="handleDeleteSplashscreen"
        @submitModalFunction="handleEditSplashscreen"
      />
    </a-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import apiClient from '@/services/axios'
import accessMix from '@/services/accessmix.js'
import splashscreenCard from '@/components/kit/widgets/General/splashscreencard'
import addSplashscreenComponent from './addSplashscreen'
import editSplashscreenComponent from './editSplashscreen'

export default {
  name: 'Splashscreens',
  mixins: [accessMix],
  components: {
    splashscreenCard,
    addSplashscreenComponent,
    editSplashscreenComponent,
  },
  created() {
    this.$root.$on('splashscreensActiveChange', () => {
      this.getSplashscreens()
    })
  },
  data: () => ({
    editSplashscreenObj: undefined,
    statInfo: {
      sections: [],
    },
    splashscreensLoading: false,
    activeOnly: true,
    filteredSplashscreens: [],
    filteredLocalizations: [],
    filteredApps: [],
    localizations: [],
    displays: [
      { label: 'Live', value: 1 },
      { label: 'Line', value: 2 },
      { label: 'Promo', value: 3 },
    ],
    addModal: false,
    editModal: false,
    filterModal: false,
    filtersDrawer: false,
    editModalTrigger: true,
    drag: false,
    apps: undefined,
    showType: 'all',
  }),
  computed: {
    ...mapState(['user']),
    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      }
    },
  },
  async mounted () {
    await this.getLocalizations()
    await this.getApplications()
    await this.getSplashscreens()
  },
  methods: {
    async getSplashscreens() {
      this.splashscreensLoading = true
      const lczStr = this.filteredLocalizations.sort(function(a, b) { return a - b }).join('&lang_ids[]=')
      const appsStr = this.filteredApps.sort(function(a, b) { return a - b }).join('&app_ids[]=')
      const url = `/admin/splashscreens?lang_ids[]=${lczStr}&active_only=${this.activeOnly}&app_ids[]=${appsStr}`
      try {
        const response = await apiClient.get(url)
        this.filteredSplashscreens = response.data.data
      } catch (error) {
        console.log('Error while trying to get splashscreens', error)
      } finally {
        this.splashscreensLoading = false
      }
    },
    getSections() {
      const url = '/admin/get-sections'
      apiClient.get(url).then((response) => {
        this.statInfo.sections = response.data.data
      }).catch(error => { console.log(error) })
    },
    async getApplications() {
      this.splashscreensLoading = true
      const url = '/admin/applications'
      try {
        const response = await apiClient.get(url)
        this.apps = response.data.data
        this.apps.forEach(item => {
          if (item.is_default === 1) {
            this.activeApp = item
            this.appId = item.application_id
            this.appName = item.name
          }
        })
        this.filteredApps = this.apps.map(app => app.application_id)
      } catch (error) {
        console.log('Error while trying to get applications', error)
      } finally {
        this.splashscreensLoading = false
      }
    },
    async getLocalizations() {
      this.splashscreensLoading = true
      const url = '/admin/localizations'
      try {
        const response = await apiClient.get(url)
        this.localizations = response.data.data.map(function(row) {
          return { value: row.id, label: row.desc }
        })
        this.filteredLocalizations = this.localizations.map(value => value.value)
      } catch (error) {
        console.log('Error while trying to get locales', error)
      } finally {
        this.splashscreensLoading = false
      }
    },
    createBannerModal() {
      this.addModal = true
    },
    handleAddSplashscreen(e) {
      this.addModal = false
    },
    handleCreateSplashscreen(e) {
      console.log('successfully created. updating splashscreen list')
      this.getSplashscreens().then(() => { console.log('sort after added') })
      this.addModal = false
    },
    handleEditSplashscreen(e) {
      console.log('successfully updated. updating splashscreen list')
      this.getSplashscreens().then(() => { console.log('sort after added') })
      this.editModal = false
    },
    handleDeleteSplashscreen(e) {
      console.log('successfully delete. updating splashscreen list')
      this.getSplashscreens().then(() => { console.log('sort after added') })
      this.editModal = false
    },
    handleActiveSwitch(checked) {
      this.getSplashscreens()
    },
    handleLocalizationChange(e) {
      this.getSplashscreens()
    },
    handleAppsChange(e) {
      this.getSplashscreens()
    },
  },
}
</script>

<style scoped>
.splashscreens-loading-spinner {
  /*width: 64px;*/
  /*height: 64px;*/
  border-radius: 2px;
  padding: 1rem;
  font-size: 96px;
  /*background: rgba(0, 0, 0, 0.1);*/
  margin-top: 6rem;
  position: absolute;
  margin-left: calc(50% - 40px);
  z-index: 999999;
}
.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 24px;
  padding: 24px 0;
  background: #fff;
}
@media screen and (max-width: 1300px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 900px) {
  .grid {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>
<style lang="scss">
.filter-drawer .ant-drawer-content-wrapper,
.filter-drawer .ant-drawer-content,
.filter-drawer .ant-drawer-wrapper-body {
  overflow: initial;
}
@media (max-width: 991px) {
  .ant-drawer-content-wrapper {
    height: 207px !important;
  }
  .ant-drawer-body  {
    padding: 5px 24px !important;
    & .ant-form-item {
      margin-bottom: 0;
    }
  }
}
@media (max-width: 575px) {
  .ant-drawer-content-wrapper {
    height: 276px !important;
  }
}
</style>
